<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>

    <div class="order-list-container nav-page-container">
      <van-search
          v-model.trim="searchName"
          shape="round"
          show-action
          placeholder="请输入搜索关键词"
          @search="handleOnSearch"
      >
        <template #action>
          <div class="search-btn" @click="handleOnSearch">搜索</div>
        </template>
      </van-search>
      <div class="search-status-container">
        <div
            v-for="status in statusSearchList"
            :key="status.name"
            :class="{ checked: status.id === searchForm.status }"
            @click="() => {
            searchForm.status = status.id;
            onRefresh();
          }"
        >
          {{ status.name }}
        </div>
      </div>
      <page-container
          ref="pageContainer"
          class="order-list-scroller"
          :scroll-top.sync="scrollTop"
      >
        <!-- 下拉刷新 -->
        <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            style="position: relative;z-index: 1"
        >
          <template #loading>
            {{ pagination.pageNum < 2 ? '' : '加载中...' }}
          </template>

          <!-- 更多列表加载 -->
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <!-- 订单 -->
            <div
                v-for="item of dataList"
                class="order-info-item"
                @click="handleOrderDetail(item)"
            >
              <div class="agency-container">
                <span class="agency-name"><van-icon name="shop-o"/>{{ item.agencyName }}</span>
                <div class="order-status-name">{{ getOrderStatusName(item.status) }}</div>
              </div>
              <div v-for="(ele,i) in item.linkGoodsList">
                <goods-info
                    view-model="small"
                    :value="{
                  goodsImgSrc: ele.tagIcon || ele.thumbUrl || item.thumbImageUrl,
                  goodsName: ele.goodsName,
                  goodsDesc: ele.brandName,
                  goodsPrice: item.amount,
                  tagTitle: ele.tagTitle,
                }"
                >
                  <template #price-right>
                    ×{{ ele.count }}
                  </template>
                </goods-info>

              </div>

              <div class="btn-container">
                <van-button
                    v-if="getBtnShow('退换/售后', item.status)" size="small" round plain color="#666"
                    @click.stop="handleApplyForReturn(item)"
                >退换/售后
                </van-button>
                <van-button
                    v-if="getBtnShow('取消申诉', item.status, item)" size="small" round plain color="#666"
                    @click.stop="handleCancelAdvice(item)"
                >取消申诉
                </van-button>
                <van-button
                    v-if="getBtnShow('查看物流', item.status)" size="small" round plain color="#666"
                    @click.stop="handleExpressListView(item)"
                >查看物流
                </van-button>
                <!--              <van-button-->
                <!--                  v-if="getBtnShow('查看物流', item.status)" size="small" round plain color="#666"-->
                <!--                  @click.stop="handleExpressView(item)"-->
                <!--              >查看物流-->
                <!--              </van-button>-->


                <van-button
                    v-if="getBtnShow('取消订单', item.status)"
                    size="small" round plain color="#666"
                    @click.stop="handleOnCancelOrder(item)"
                >取消订单
                </van-button>
                <van-button
                    v-if="getBtnShow('去付款', item.status)"
                    size="small" round plain type="primary"
                >去付款
                </van-button>
                <van-button
                    v-if="getBtnShow('确认收货', item.status)" size="small" round type="primary"
                    @click.stop="handleConfirmOrder(item)"
                >确认收货
                </van-button>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>

      </page-container>

      <van-overlay
          v-if="expressListShow"
          :show="true"
          :lock-scroll="false"
          @click="expressListShow = false"
      >
        <div @click.stop>
          <express-view-list
              :order-id="expressOrderId"
              @express-click="handleExpressView"
          />
        </div>
      </van-overlay>

      <van-overlay
          v-if="expressShow"
          :show="true"
          @click="() => {
          !expressLoading && (this.expressShow = false)
        }"
          :lock-scroll="false"
      >
        <div v-show="!expressLoading">
          <express-info
              :order-id="expressOrderId"
              :loading.sync="expressLoading"
              @change="data => {
              this.expressShow = !!data;
            }"
          />
        </div>
        <van-loading v-show="expressLoading">查询中...</van-loading>
      </van-overlay>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cancelOrder, getAllOrdersByPage,getAllOrdersByMainPage, confirmOrder, adviceCancelOrder } from '@/api/normalOrder';
import {
  DENG_DAI_PIN_TUAN_WAN_CHENG,
  DAI_FU_KUAN,
  DAI_FA_HUO,
  DAI_SHOU_HUO,
  YI_WAN_CHENG,
  YI_GUAN_BI,
  WEI_QUAN_SHEN_QING,
  WEI_QUAN_WAN_CHENG,
  DING_DAN_QU_XIAO,
  ORDER_STATUS_MAP, APPLY_FOR_RETURN_STATUS,
} from '@/const/orderStatus';
import GoodsInfo from '@/components/GoodsInfo';
import { objectGetter } from '@/util';
import { Dialog, Toast } from 'vant';
import ExpressInfo from '@/components/ExpressInfo';
import ExpressViewList from '@/components/ExpressViewList';
import { SHANG_PU_TONG_YI } from '@/const/afterSale';
import {  cancelMainOrder } from '@/api/shoppingCart'

export default {
  name: 'orderList',
  components: { ExpressViewList, ExpressInfo, GoodsInfo },
  data() {
    return {
      searchName: '',
      searchForm: {
        goodsName: '',
        status: '',
      },

      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
      statusSearchList: [
        { id: '', name: '全部' },
        { id: DAI_FU_KUAN, name: ORDER_STATUS_MAP[DAI_FU_KUAN] },
        { id: DAI_SHOU_HUO, name: ORDER_STATUS_MAP[DAI_SHOU_HUO] },
        { id: YI_GUAN_BI, name: ORDER_STATUS_MAP[YI_GUAN_BI] },
      ],

      expressListShow: false,

      expressShow: false,
      expressLoading: true,
      expressOrderId: '',
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.orderList.refreshing,
    }),
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('orderList/setRefreshing', false);

      Object.assign(this, {
        searchName: '',
        searchForm: {
          goodsName: '',
          status: '',
        },
        dataList: [],
        pagination: {
          // 分页参数
          pageNum: 0,
          pageSize: 10,
          total: 0,
        },
        expressShow: false,
        expressLoading: true,
        expressOrderId: '',
      });

      let routeQueryStatus = this.$route.query.status;
      this.searchForm.status = ORDER_STATUS_MAP[routeQueryStatus] ? routeQueryStatus : '';

      this.onRefresh();
    }, { immediate: true });
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    objectGetter,
    handleOnSearch() {
      this.searchForm.goodsName = this.searchName;
      this.onRefresh();
    },

    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;
      let serve=null
      if(!this.searchForm.status || this.searchForm.status ==='01'){
         serve= getAllOrdersByMainPage   
      }else{
         serve=  getAllOrdersByPage
      }
// 
      return serve({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    getOrderStatusName(status) {
      return ORDER_STATUS_MAP[status];
    },
    getBtnShow(btnName, status, item) {
      if (btnName === '取消订单') {
        return status === DAI_FU_KUAN;// || status === DAI_FA_HUO;
      }
      if (btnName === '去付款') {
        return status === DAI_FU_KUAN;
      }
      if (btnName === '确认收货') {
        return status === DAI_SHOU_HUO;
      }
      if (btnName === '退换/售后') {
        return APPLY_FOR_RETURN_STATUS.includes(status);
      }
      if (btnName === '取消申诉') {
        const expressNum = objectGetter(item, 'orderReturn.expressNum');
        const orderReturnStatus = objectGetter(item, 'orderReturn.status');
        const orderReturnType = objectGetter(item, 'orderReturn.type'); // 01 退货退款; 02 退款;
        return status === WEI_QUAN_SHEN_QING
            && !expressNum
            && !(orderReturnType === '01' && orderReturnStatus === SHANG_PU_TONG_YI);
      }
      if (btnName === '查看物流') {
        return [
          DAI_SHOU_HUO,
          YI_WAN_CHENG,
          YI_GUAN_BI,
          WEI_QUAN_SHEN_QING,
          WEI_QUAN_WAN_CHENG,
        ].includes(status);
      }
      return false;
    },
    // 维权/售后
    handleApplyForReturn(order) {
      this.$router.push({
        path: '/applyForReturn',
        query: {
          orderId: order.id,
        }
      });
    },
    // 取消维权申述
    handleCancelAdvice(order) {
      Dialog.confirm({
        title: `确认取消申诉么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            adviceCancelOrder({ returnId: objectGetter(order, 'orderReturn.id') }).then(res => {
              done();

              if (res === true) {
                Toast('取消申诉成功');
                this.onRefresh();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });

    },

    // 订单详情
    handleOrderDetail(order) {
      console.log()
      if(order.orderNo.substr(0, 1)!=='M'){
        this.$router.push({
        path: '/orderDetail',
        query: {
          orderId: order.id,
        },
      });

      }else{
        this.$router.push({
        path: '/shoppingCartOrderDetail',
        query: {
          orderNo: order.orderNo,
        },
      });
      }
      
    },
    // 查看物流列表
    handleExpressListView(order) {
      Object.assign(this, {
        expressOrderId: order.id,
        expressListShow: true,
      });
    },

    // 查看物流
    handleExpressView(express) {
      Object.assign(this, {
        expressOrderId: express.id,
        expressShow: true,
      });

      return console.log(express);

      Object.assign(this, {
        expressGoodsName: objectGetter(order, 'linkGoods.goodsName'),
        expressOrderId: order.id,
        expressShow: true,
      });
    },
    // 取消订单
    handleOnCancelOrder(order) {
      Dialog.confirm({
        title: `确认取消订单么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            if(order.orderNo.substr(0,1)=='M') {
              cancelMainOrder(order.orderNo).then(res => {
              if (res === true) {
               this.onRefresh();
                done();
              }
            });

            }else{
              cancelOrder({ orderId: order.id }).then(res => {
                 if (res === true) {
                   this.onRefresh();
                   done();
                 }
              });

            }
            
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
    // 确认收货
    handleConfirmOrder(order) {
      Dialog.confirm({
        title: `是否确认收货？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            confirmOrder({ orderId: order.id }).then(res => {
              if (res === true) {
                this.onRefresh();
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-list-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  .search-btn {
    padding: 0 7px;
    color: @primary-color;
  }

  @search-status-container-height: 40px;

  .search-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @search-status-container-height;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;
      font-size: 14px;
      color: #555;
      text-align: center;
      transition: all .33s;

      &.checked {
        position: relative;
        font-size: 16px;
        font-weight: bolder;
        color: @primary-color;
      }
    }
  }

  .order-list-scroller {
    top: @search-padding * 2 + @search-input-height + @search-status-container-height;

    .order-info-item {
      margin: 10px;
      padding: 10px;
      border-radius: 15px;
      background-color: #fff;

      .agency-container {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 4px 0 14px 0;
        line-height: 1.2;
        font-size: 16px;
        color: #333;

        .agency-name {
          padding: 0 10px 0 0;

          .van-icon {
            margin-right: 8px;
            font-size: 18px;
          }
        }

        .order-status-name {
          flex-shrink: 0;
          font-size: 16px;
          color: @primary-color;
        }
      }

      .btn-container {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
        margin-top: 20px;
        padding: 5px 0;
        //height: 46px;

        button {
          padding: 0 12px;
          font-size: 14px;
          margin: 5px 0 5px 10px;
        }
      }
    }
  }

  /deep/ .van-overlay {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      padding: 15px;
      width: 80%;
      max-height: 80%;
      background-color: #fff;
      border-radius: 15px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}
</style>
