<template>
  <div>
    <div
        class="express-list-item"
        v-for="express in expressList"
        @click="$emit('express-click', express)"
    >
      <div class="title-info">
        <div>
          快递单号：{{ express.expressNumber }}
        </div>
        <span></span>
      </div>
      <div class="detail-item">
        <label>快递公司</label>
        <span>{{ expressNameMap[express.expressCompany] }}</span>
      </div>
      <div class="detail-item">
        <label>发货时间</label>
        <span>{{ express.sendTime }}</span>
      </div>
    </div>
    <van-empty
        v-if="!expressList.length && !loading"
        description="暂无物流信息"
    />
    <div v-if="loading" style="text-align: center">
      <van-loading>加载中...</van-loading>
    </div>
  </div>
</template>

<script>
import { getExpressList, getOrderInfoById } from '@/api/normalOrder';
import { objectGetter } from '@/util';

export default {
  name: 'ExpressViewList',
  props: {
    orderId: {
      default: () => '',
    },
  },
  data() {
    return {
      loading: true,
      expressList: [],
      expressDicList: [],
    };
  },
  computed: {
    expressNameMap() {
      return [{}, ...this.expressDicList].reduce((map, e) => ({
        ...map,
        [e.id]: e.name,
      }));
    },
  },
  created() {
    const { orderId } = this;
    if (orderId) {
      getOrderInfoById({
        id: orderId,
      }).then(res => {
        this.loading = false;
        this.expressList = res.expressList || [];
      });

      getExpressList().then(res => {
        this.expressDicList = res || [];
      });
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.express-list-item {
  margin: 10px;
  padding: 0 0 10px 0;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 4px 4px #f2f2f2;
  box-shadow: 0 0 4px 4px #f2f2f2;


  .title-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 10px;
    border-bottom: 1px dashed #dedede;
    border-left: 4px solid #0000;

    & > div {
      font-size: 15px;
      color: #333;
    }

    & > span {
      font-size: 15px;
      color: #666;
    }
  }

  .detail-item {
    padding: 0 14px;
    line-height: 1.4;

    & > label {
      display: inline-block;
      width: 5.5em;
      color: #999;

      &:after {
        content: '：';
      }
    }

    & > span {
      color: #666;
    }
  }
}

</style>
